import Image from 'next/legacy/image'
import { Trans, useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { breakpoints, colors, fontWeights } from '@/styles'
import useLanguage from '@/hooks/use-language'
import { AndroidButton, AppleButton } from '@/components/app-buttons'
import { PlayVideoIcon } from '@/components/icons'

import hero from './images/hero@3x.png'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  padding-top: 110px;

  @media (max-width: ${breakpoints.phoneMax}) {
    padding-top: 80px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const ContentWrapper = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 490px;
  margin-right: 8vw;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    margin-right: 0;
    align-items: center;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    align-items: flex-start;
    margin-right: 25px;
    margin-left: 25px;
  }
`

const ProductImageWrapper = styled.div`
  position: relative;
  width: 490px;
  height: 800px;
  filter: drop-shadow(40px 10px 10px #bfbfbf);

  @media (max-width: ${breakpoints.desktop}) {
    width: 425px;
    height: 681px;
  }

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    width: 400px;
    height: 652px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    width: 325px;
    height: 530px;
  }
`

const Title = styled.h2<{ currentLang: string }>`
  font-family: futura-pt-bold, Futura, sans-serif;
  font-weight: 700;
  font-size: ${props => (props.currentLang === 'zh' ? '39px' : '45.5px')};
  line-height: 46px;
  color: ${colors.regular};
  margin: 25px 0 50px;
  letter-spacing: -0.14px;
  max-width: 425px;

  @media (max-width: ${breakpoints.phoneMax}) {
    font-size: 34px;
    text-align: left;
    margin: 0 0 40px;
    letter-spacing: -0.09px;
    line-height: 34px;
  }
`

const Description = styled.p`
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: 24px;
  font-weight: ${fontWeights.medium};
  color: ${colors.regular};
  max-width: 400px;
  margin: 0 0 10px;
  display: flex;
  align-items: center;

  a {
    color: ${colors.link};
    font-weight: ${fontWeights.medium};
    text-decoration: none;
    letter-spacing: -0.2px;
    line-height: 24px;
    transition: opacity 0.1s ease-in-out;

    :hover {
      opacity: 0.7;
    }
  }

  svg {
    margin: 0 0 -2px 7px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 14px;
    text-align: left;
    letter-spacing: -0.2px;
    line-height: 19px;
  }
`

const VideoLink = styled.a`
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin: 40px 0 25px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 30px 0 70px;
  }
`

const StoreLinkWrapper = styled.a`
  transition: opacity 0.1s ease-in-out;

  :hover {
    opacity: 0.7;
  }
`

const AppleLinkWrapper = styled(StoreLinkWrapper)`
  margin-right: 20px;
`

interface Props {
  forLandlords?: boolean
}

const ApplicationDownload: React.FC<Props> = ({ forLandlords = false }) => {
  const { t } = useTranslation()
  const { language } = useLanguage()

  // Firefox now hides tracking link
  const isFirefox =
    typeof window !== 'undefined'
      ? navigator.userAgent.includes('Firefox')
      : false

  const videoLink = forLandlords
    ? 'https://www.youtube.com/watch?v=XMW_qoUGbyY'
    : 'https://www.youtube.com/watch?v=hUdY-eKBuek'

  const getButtons = () => (
    <>
      <AppleLinkWrapper
        href={
          isFirefox
            ? 'https://apps.apple.com/ca/app/liv-apartment-houses-rental/id1321741040'
            : 'https://click.google-analytics.com/redirect?tid=UA-113063682-1&url=https%3A%2F%2Fitunes.apple.com%2Fca%2Fapp%2Fliv%2Fid1321741040&aid=ca.livstrategies.ether&idfa=%{idfa}&cs=website&cm=index&cn=on-page'
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <AppleButton height={45} />
      </AppleLinkWrapper>
      <StoreLinkWrapper
        href="https://play.google.com/store/apps/details?id=rent.liv.ether&referrer=utm_source%3Dwebsite%26utm_medium%3Dindex%26utm_campaign%3Don-page"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AndroidButton height={45} />
      </StoreLinkWrapper>
    </>
  )

  return (
    <Wrapper>
      <InnerContainer>
        <ContentWrapper>
          <Title currentLang={language.value}>
            {t('Rental solutions at your fingertips.')}
          </Title>
          <Description>
            {t(
              'Download the app to expedite and streamline your rental process, all on one platform.'
            )}
          </Description>
          <Description>
            <Trans i18nKey="Watch the video demo">
              <VideoLink
                href={videoLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Watch the video demo
                <PlayVideoIcon height={16} width={18} />
              </VideoLink>
            </Trans>
          </Description>
          <ButtonWrapper>{getButtons()}</ButtonWrapper>
        </ContentWrapper>
        <ProductImageWrapper>
          <Image
            src={hero}
            sizes={`(max-width: ${breakpoints.phoneMax}) 325px, (max-width: ${breakpoints.ipadMiniMax}) 400px, (max-width: ${breakpoints.desktop}) 425px, 490px`}
            placeholder="blur"
            layout="fill"
            alt="liv.rent Apartment rental app"
          />
        </ProductImageWrapper>
      </InnerContainer>
    </Wrapper>
  )
}

export default ApplicationDownload
